import { Box, Paper, Switch, Typography } from "@material-ui/core";
import {
  ProfitAndLossColumn,
  ProfitAndLossData,
  ProfitAndLossTable,
} from "./profitAndLossTable";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { CurrentStore } from "~/typedef/store";
import { DateRange } from "~/typedef/date";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { get } from "lodash";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useMonthlyVendorProfitabilityDataQuery } from "~/store/mystore/vendorProfitability.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface VendorMonthlyProfitAndLossProps {
  title: string;
  store: CurrentStore;
  currentRange: DateRange;
  currentCurrency: string;
  sellType: SellType;
}

const PanelWrapper = styled(Paper)`
  overflow: hidden;
`;

const PanelHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const LoadingWrapper = styled(Box)`
  width: 100%;
  height: 200px;
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataWrapper = styled(Box)`
  overflow-x: auto;
`;

const VendorMonthlyProfitAndLoss = memo<VendorMonthlyProfitAndLossProps>(
  function VendorMonthlyProfitAndLoss({
    title,
    store,
    currentRange,
    currentCurrency,
    sellType,
  }) {
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const containerRef = useRef<HTMLDivElement | null>(null);

    const [showBreakdown, setShowBreakdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const currency = getCurrencyByCountryCode[store.marketplaceCountry];

    const { isFetching, totals, monthlyData, months } =
      useMonthlyVendorProfitabilityDataQuery(
        {
          mid: store.merchantId,
          marketplaceType: store.marketplace,
          marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
          countryCode: store.marketplaceCountry,
          currentRange,
          sellType,
        },
        {
          selectFromResult: ({ data, isFetching }) => {
            return {
              months: Object.keys(data?.monthlyData || {}),
              monthlyData: data?.monthlyData || {},
              totals: data?.totals,
              isFetching,
            };
          },
        }
      );

    useEffect(() => {
      // force artificial loading state to show loading indicator
      // when user toggles the detailed P&L, loading indicator makes the transition smoother
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500); // 0.5 seconds
    }, [showBreakdown]);

    const handleCurrencyValue = useCallback(
      (value: number) =>
        value <= 0
          ? "-"
          : formatCurrency(value, currencyRates, currency, currentCurrency),
      [currencyRates, currentCurrency, currency]
    );

    const getFormattedDataByField = useCallback(
      (
        months: string[],
        field: string,
        type: "int" | "percentage" | "currency" = "currency"
      ) =>
        months.reduce<Record<string, string>>((acc, key) => {
          acc[key] =
            type === "currency"
              ? handleCurrencyValue(get(monthlyData[key], field, 0))
              : type === "percentage"
              ? `${get(monthlyData[key], field, 0).toFixed(2)}%`
              : numberWithCommas(get(monthlyData[key], field, 0));
          return acc;
        }, {}),
      [monthlyData, handleCurrencyValue]
    );

    const formattedTableData = useMemo((): {
      income: ProfitAndLossData[];
      expense: ProfitAndLossData[];
      profit: ProfitAndLossData[];
      metrics: ProfitAndLossData[];
    } => {
      if (!totals || !monthlyData) {
        return {
          income: [],
          expense: [],
          profit: [],
          metrics: [],
        };
      }

      return {
        income: [
          {
            key: t(`vendorProfitAndLoss.shippedCogsLabel`),
            tooltip: t(`vendorProfitAndLoss.shippedCogsTooltip`),
            total: handleCurrencyValue(totals.shippedCogs),
            ...getFormattedDataByField(months, "shippedCogs"),
          },
          {
            key: t(`vendorProfitAndLoss.netRevenueLabel`),
            total: handleCurrencyValue(totals.shippedCogs),
            bold: true,
            ...getFormattedDataByField(months, "shippedCogs"),
          },
        ],
        expense: [
          {
            key: t(`vendorProfitAndLoss.adCostLabel`),
            tooltip: t(`vendorProfitAndLoss.adCostTooltip`),
            total: handleCurrencyValue(totals.adCost),
            ...getFormattedDataByField(months, "adCost"),
          },
          {
            key: t(`vendorProfitAndLoss.netDeductionsLabel`),
            tooltip: t(`vendorProfitAndLoss.netDeductionsTooltip`),
            total: handleCurrencyValue(totals.expenses.deductions),
            ...getFormattedDataByField(
              months,
              "expenses.currentMonthDeduction"
            ),
          },
          ...(showBreakdown
            ? totals.availableDeductions.map((deduction) => ({
                key: t(`vendorProfitAndLoss.${deduction}Label`),
                startAlign: false,
                total: handleCurrencyValue(
                  get(totals, `expenses.individualDeductions.${deduction}`, 0)
                ),
                ...getFormattedDataByField(
                  months,
                  `expenses.deductions.${deduction}`
                ),
              }))
            : []),
          {
            key: t(`vendorProfitAndLoss.cogsLabel`),
            tooltip: t(`vendorProfitAndLoss.cogsTooltip`),
            total: handleCurrencyValue(totals.expenses.cogs),
            ...getFormattedDataByField(months, "expenses.currentMonthCogs"),
          },
          {
            key: t(`vendorProfitAndLoss.netChargebacksLabel`),
            tooltip: t(`vendorProfitAndLoss.netChargebacksTooltip`),
            total: handleCurrencyValue(totals.expenses.chargebacks),
            ...getFormattedDataByField(
              months,
              "expenses.currentMonthChargeback"
            ),
          },
          ...(showBreakdown
            ? totals.availableChargebacks.map((chargeback) => ({
                key: chargeback,
                startAlign: false,
                total: handleCurrencyValue(
                  get(totals, `expenses.individualChargebacks.${chargeback}`, 0)
                ),
                ...getFormattedDataByField(
                  months,
                  `expenses.chargebacks.${chargeback}`
                ),
              }))
            : []),
          {
            key: t(`vendorProfitAndLoss.netExpenseLabel`),
            total: handleCurrencyValue(totals.netExpenses),
            bold: true,
            ...getFormattedDataByField(months, "totalExpenses"),
          },
        ],
        profit: [
          {
            key: t(`vendorProfitAndLoss.profitLabel`),
            total: handleCurrencyValue(totals.profit),
            bold: true,
            ...getFormattedDataByField(months, "profit"),
          },
          {
            key: t(`vendorProfitAndLoss.profitPercentLabel`),
            total: `${totals.profitPercentage.toFixed(2)}%`,
            colorCode: totals.profitPercentage < 0 ? "error" : "success",
            ...getFormattedDataByField(
              months,
              "profitPercentage",
              "percentage"
            ),
          },
        ],
        metrics: [
          {
            key: t(`vendorProfitAndLoss.tacosLabel`),
            total: `${totals.tacos.toFixed(2)}%`,
            ...getFormattedDataByField(months, "tacos", "percentage"),
          },
          {
            key: t(`vendorProfitAndLoss.totalFeesLabel`),
            total: `${totals.totalFees.toFixed(2)}%`,
            ...getFormattedDataByField(months, "totalFees", "percentage"),
          },
          {
            key: t(`vendorProfitAndLoss.unitsShippedLabel`),
            total: numberWithCommas(totals.shippedUnits),
            ...getFormattedDataByField(months, "shippedUnits", "int"),
          },
          {
            key: t(`vendorProfitAndLoss.snsUnitsLabel`),
            tooltip: t(`vendorProfitAndLoss.snsUnitsTooltip`),
            total: numberWithCommas(totals.snsUnits),
            ...getFormattedDataByField(months, "snsUnits", "int"),
          },
          {
            key: t(`vendorProfitAndLoss.snsPenLabel`),
            total: `${totals.snsPenetration.toFixed(2)}%`,
            ...getFormattedDataByField(months, "snsPenetration", "percentage"),
          },
        ],
      };
    }, [totals, months]);

    const getMonthlyDataColumnWidth = useCallback((columnCount: number) => {
      const containerRefWidth = containerRef?.current?.clientWidth || 0;
      const columnWidth = (containerRefWidth - 300) / columnCount;

      if (columnCount === 1) return "45%";

      return columnWidth < 80 ? "100px" : `${columnWidth}px`;
    }, []);

    const columns = useMemo((): ProfitAndLossColumn[] => {
      return [
        {
          header: t(`profitability.period`),
          key: "key",
          bold: true,
          uppercase: true,
          startAlign: true,
          width: "175px",
          // stick the first column
          stickyLeft: "0",
        },
        {
          header: t("generic.total"),
          key: "total",
          bold: true,
          startAlign: false,
          uppercase: true,
          width: "125px",
          divider: "right",
          // stick the second column
          // this is a workaround to make the second column sticky
          // 140 width + 30 padding = 180px
          stickyLeft: "175px",
        },
        ...months.map((key) => ({
          header: moment(key).format("MMM'YY"),
          key,
          bold: true,
          startAlign: false,
          uppercase: true,
          width: getMonthlyDataColumnWidth(months.length),
        })),
      ];
    }, [months]);

    return (
      <PanelWrapper ref={containerRef}>
        <PanelHeader>
          <Typography variant="h3">{title}</Typography>
          <FlexBox>
            <Typography variant="subtitle1" color="textPrimary" noWrap>
              {t("profitability.expandProfitAndLoss")}
            </Typography>
            <Switch
              size="small"
              checked={showBreakdown}
              onClick={() => {
                setShowBreakdown(!showBreakdown);
              }}
            />
          </FlexBox>
        </PanelHeader>
        <Box>
          {loading || isFetching ? (
            <LoadingWrapper>
              <PanelLoading />
            </LoadingWrapper>
          ) : (
            <DataWrapper>
              <ProfitAndLossTable
                {...formattedTableData}
                columns={columns}
                monthsCount={months.length}
              />
            </DataWrapper>
          )}
        </Box>
      </PanelWrapper>
    );
  }
);

export default VendorMonthlyProfitAndLoss;
