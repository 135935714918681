import { Box, Tooltip, Typography } from "@material-ui/core";

import { LightInlineIconButton } from "~/icons/inlineIconButton";
import React from "react";
import styled from "styled-components";

interface CellProps {
  value: string;
  startAlign?: boolean;
  tooltip?: string;
  bold?: boolean;
  width?: string;
  uppercase?: boolean;
  stickyLeft?: string;
  divider?: string;
  isSectionHeader?: boolean;
  colorCode?: "success" | "error";
  actions?: React.ReactNode;
}

const Wrapper = styled(Box)<{
  $startAlign?: boolean;
  $width?: string;
  $divider?: string;
  $stickyLeft?: string;
  $isSectionHeader?: boolean;
  $colorCode?: "success" | "error";
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  width: ${({ $width }) => $width || "20%"};
  min-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ $colorCode, theme }) =>
    $colorCode === "error"
      ? `color: ${theme.palette.error.main};`
      : $colorCode === "success"
      ? `color: ${theme.palette.success.main};`
      : ""}

  ${({ $isSectionHeader, theme }) =>
    $isSectionHeader
      ? `color: ${theme.palette.table.text.header};
  font-size: 0.875rem;`
      : `background-color: ${theme.palette.background.paper};
  font-size: 0.75rem;`}
  ${({ $stickyLeft }) =>
    $stickyLeft
      ? `position: sticky;
        left: ${$stickyLeft};
        z-index: 1;`
      : ""}
  ${({ $divider, theme }) =>
    $divider
      ? `border-${$divider}: 1px solid ${theme.palette.border.main};`
      : ""}
  ${({ $startAlign }) =>
    $startAlign
      ? `justify-content: flex-start;`
      : `justify-content: flex-end; text-align: right; `}
`;

const TextWrapper = styled(Typography)<{
  $bold?: boolean;
  $uppercase?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: ${({ $bold }) => ($bold ? "bold" : "normal")};
  text-transform: ${({ $uppercase }) => ($uppercase ? "uppercase" : "none")};
`;

export const Cell = ({
  value,
  tooltip,
  startAlign,
  bold,
  width,
  uppercase,
  actions,
  stickyLeft,
  divider,
  isSectionHeader,
  colorCode,
}: CellProps) => {
  return (
    <Wrapper
      $startAlign={startAlign}
      $width={width}
      $stickyLeft={stickyLeft}
      $divider={divider}
      $isSectionHeader={isSectionHeader}
      $colorCode={colorCode}
    >
      {tooltip && (
        <Tooltip title={tooltip}>
          <LightInlineIconButton />
        </Tooltip>
      )}
      <TextWrapper variant="body1" $bold={bold} $uppercase={uppercase}>
        {value}
      </TextWrapper>
      {actions}
    </Wrapper>
  );
};
