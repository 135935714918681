import { TAG_TYPES, api } from "../apiSlice";

import { DateRange } from "~/typedef/date";
import { PaginationArgs } from "~/typedef/pagination";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { setInfoMessage } from "../globalToast.redux";

interface VendorProfitabilityBaseStoreArgs {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
}

interface VendorChannelProfitAndLossArgs
  extends VendorProfitabilityBaseStoreArgs {
  currentRange: DateRange;
  sellType: SellType;
  isComparison?: boolean;
}

export interface VendorChannelProfitAndLossResponse {
  income: Record<string, number>;
  expense: Expense;
  metrics: Record<string, number>;
  currency: string;
  snsFromDate: number;
  snsToDate: number;
}

export interface Expense {
  adCost: number;
  deduction?: Record<string, number>;
  cogs?: { cogs: number };
  chargeback?: Record<string, number>;
}

interface ChargebackUploadArgs extends VendorProfitabilityBaseStoreArgs {
  successMessage: string;
  file: File;
}

interface ChargebackUploadResponse {
  message: string;
  skippedChargebackCount: number;
  skippedVendorCodes?: string[];
}

interface CogsUploadArgs extends VendorProfitabilityBaseStoreArgs {
  successMessage: string;
  file: File;
  fromDate: number;
}

interface CogsUploadResponse {
  message: string;
}

// Will later contain searchText
interface ProductProfitabilityRequest {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  includeTax?: boolean;
  countryCode: string;
  currentRange: {
    fromDate: number;
    toDate: number;
    timezone: string;
    interval: string;
  };
  paginationParams: PaginationArgs;
  searchText: string;
  sellType: SellType;
}

type CommonProductProfitabilityRow = {
  asin: string;
  title: string;
  imageUrl: string;
  linkUrl: string;
  advertisingCost: number;
  deductions: number;
  cogs: number;
  chargebacks: number;
  expenses: number;
  profit: number;
  inventoryQuantity: number;
};

export type SellInProductProfitabilityRow = CommonProductProfitabilityRow & {
  poValue: number;
  unitsReceived: number;
};

export type SellOutProductProfitabilityRow = CommonProductProfitabilityRow & {
  shippedCogs: number;
  unitsShipped: number;
  snsUnits: number;
};

export type ProductProfitabilityRow =
  | SellInProductProfitabilityRow
  | SellOutProductProfitabilityRow;

export interface ProductProfitabilityResponse {
  rows: ProductProfitabilityRow[];
  count: number;
}

interface DeductionsRequest {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  paginationParams: PaginationArgs;
}

export enum DeductionBasis {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  NET_RECEIPTS = "NET_RECEIPTS",
}

export enum DeductionType {
  DAMAGE_ALLOWANCE = "DAMAGE_ALLOWANCE",
  CO_OP = "CO_OP",
  FREIGHT_ALLOWANCE = "FREIGHT_ALLOWANCE",
  SAS = "SAS",
  PRICE_PROTECTION = "PRICE_PROTECTION",
  PROMOTIONAL_ALLOWANCE = "PROMOTIONAL_ALLOWANCE",
  STRAIGHT_PAYMENTS = "STRAIGHT_PAYMENTS",
  OTHER_REBATE = "OTHER_REBATE",
}

export interface HistoricalDeduction {
  deductionId: string;
  deductionType: DeductionType;
  deductionBasis: DeductionBasis;
  deductionValue: number;
  currency: string;
  vendorCode: string | null;
  effectiveDateFrom: string; // Date string
  effectiveDateTo: string; // Date string
  isActive: boolean;
}

interface DeductionsResponse {
  count: number;
  deductions: HistoricalDeduction[];
}

interface UpdateDeductionsRequest {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  deduction: {
    deductionId: string | null;
    deductionType: DeductionType;
    deductionBasis: DeductionBasis;
    deductionValue: number;
    currency: string;
    vendorCode: string;
    fromDate: number; // unix timestamp
    toDate: number; // unix timestamp
  };
  successMessage: string;
}

interface UpdateDeductionsResponse {
  message: string;
  status: string;
}

interface DeleteDeductionsRequest {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  deductionId: string;
  successMessage: string;
}

interface DeleteDeductionsResponse {
  status: string;
}

interface GetChargebackMetadataRequest {
  mid: string;
  currentRange: DateRange;
  marketplaceFilters: {
    marketplaceType: string;
    marketplaceSubtype: string;
  };
}

interface GetChargebackMetadataResponse {
  hasChargebacks: boolean;
  minChargebackDate?: string;
  maxChargebackDate?: string;
  lastChargebackUpdatedAt?: string;
}

interface UpdateProductDirectCostsResponse {
  message?: string;
  status?: string;
}

interface UpdateProductDirectCostsParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  fromDate: number; //unix timestamp
  directCost: {
    productSku: string;
    eventCurrency: string;
    eventValue: number;
  };
  successMessage: string;
}

export interface VendorProfitabilityMonthlySellOutDataResponse {
  totals: Totals;
  monthlyData: Record<string, MonthlyData>;
}

export interface MonthlyData {
  date: string;
  snsUnits: number;
  adCost: number;
  shippedCogs: number;
  shippedUnits: number;
  expenses: MonthlyExpenses;
  totalExpenses: number;
  profit: number;
  profitPercentage: number;
  tacos: number;
  totalFees: number;
  snsPenetration: number;
}

export interface MonthlyExpenses {
  chargebacks: Record<string, number>;
  deductions: Record<string, number>;
  cogsExpenses: Record<string, number>;
  currentMonthChargeback: number;
  currentMonthDeduction: number;
  currentMonthCogs: number;
}

export interface Totals {
  snsUnits: number;
  adCost: number;
  shippedCogs: number;
  shippedUnits: number;
  expenses: TotalsExpenses;
  availableChargebacks: string[];
  availableDeductions: string[];
  netExpenses: number;
  profit: number;
  profitPercentage: number;
  tacos: number;
  totalFees: number;
  snsPenetration: number;
}

export interface TotalsExpenses {
  chargebacks: number;
  deductions: number;
  cogs: number;
  individualChargebacks: Record<string, number>;
  individualDeductions: Record<string, number>;
}

type VendorMonthlyProfitAndLossArgs = Omit<
  VendorChannelProfitAndLossArgs,
  "isComparison"
>;

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    VendorChannelProfitAndLoss: build.query<
      VendorChannelProfitAndLossResponse,
      VendorChannelProfitAndLossArgs
    >({
      query: (params) => {
        const { currentRange, isComparison, ...rest } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/channel`,
          method: "POST",
          data: {
            ...rest,
            currentRange: {
              ...currentRange,
              fromDate: isComparison
                ? currentRange.priorFromDate
                : currentRange.fromDate,
              toDate: isComparison
                ? currentRange.priorToDate
                : currentRange.toDate,
            },
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("VendorChannelProfitAndLoss"),
    }),
    uploadChargebackFile: build.mutation<
      ChargebackUploadResponse,
      ChargebackUploadArgs
    >({
      query: ({
        mid,
        marketplaceType,
        marketplaceSubtype,
        countryCode,
        file,
      }) => {
        const formData = new FormData();

        formData.append("mid", mid);
        formData.append("marketplaceType", marketplaceType);
        formData.append("marketplaceSubtype", marketplaceSubtype);
        formData.append("countryCode", countryCode);
        formData.append("file", file);

        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/chargeback/upload`,
          method: "POST",
          data: formData,
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "UploadChargebackFile",
        false,
        (_data, dispatch, { successMessage }) => {
          setInfoMessage(dispatch, successMessage, "UploadChargebackFile");
        }
      ),
    }),
    vendorDeductions: build.query<DeductionsResponse, DeductionsRequest>({
      query: ({ paginationParams, ...otherParams }) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/deductions`,
          method: "GET",
          params: {
            ...otherParams,
            ...paginationParams,
          },
        };
      },
      providesTags: [TAG_TYPES.VendorDeductions],
      onQueryStarted: globalQueryErrorHandler("GetVendorDeductions"),
    }),
    updateVendorDeductions: build.mutation<
      UpdateDeductionsResponse,
      UpdateDeductionsRequest
    >({
      query: (params) => {
        const { mid, marketplaceType, marketplaceSubtype, deduction } = params;
        if (deduction.deductionId) {
          // Update
          return {
            url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/deductions`,
            method: "PUT",
            data: { mid, marketplaceType, marketplaceSubtype, deduction },
          };
        } else {
          // Create
          return {
            url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/deductions`,
            method: "POST",
            data: { mid, marketplaceType, marketplaceSubtype, deduction },
          };
        }
      },
      invalidatesTags: [TAG_TYPES.VendorDeductions],
      onQueryStarted: globalQueryErrorHandler(
        "UpdateVendorRebates",
        false,
        (_, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage, "UpdateVendorRebates");
        }
      ),
    }),
    deleteVendorDeductions: build.mutation<
      DeleteDeductionsResponse,
      DeleteDeductionsRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/deductions`,
          method: "DELETE",
          data: params,
        };
      },
      invalidatesTags: [TAG_TYPES.VendorDeductions],
      onQueryStarted: globalQueryErrorHandler(
        "DeleteVendorRebate",
        false,
        (_, dispatch, args) => {
          setInfoMessage(dispatch, args.successMessage, "DeleteVendorRebate");
        }
      ),
    }),
    uploadDirectCostFile: build.mutation<CogsUploadResponse, CogsUploadArgs>({
      query: ({
        mid,
        marketplaceType,
        marketplaceSubtype,
        countryCode,
        fromDate,
        file,
      }) => {
        const formData = new FormData();

        formData.append("mid", mid);
        formData.append("marketplaceType", marketplaceType);
        formData.append("marketplaceSubtype", marketplaceSubtype);
        formData.append("countryCode", countryCode);
        formData.append("fromDate", fromDate.toString());
        formData.append("file", file);

        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/directCosts/upload`,
          method: "PUT",
          data: formData,
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "UploadDirectCostFile",
        false,
        (_data, dispatch, { successMessage }) => {
          setInfoMessage(dispatch, successMessage);
        }
      ),
    }),
    updateProductDirectCosts: build.mutation<
      UpdateProductDirectCostsResponse,
      UpdateProductDirectCostsParams
    >({
      query: ({
        mid,
        marketplaceType,
        marketplaceSubtype,
        fromDate,
        directCost,
      }) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/directCosts/product`,
          method: "PUT",
          data: {
            mid,
            marketplaceType,
            marketplaceSubtype,
            fromDate,
            directCost,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "UpdateProductDirectCosts",
        false,
        (_data, dispatch, { successMessage }) => {
          setInfoMessage(dispatch, successMessage);
        }
      ),
      invalidatesTags: [TAG_TYPES.ProductDirectCosts],
    }),
    getProductProfitability: build.query<
      ProductProfitabilityResponse,
      ProductProfitabilityRequest
    >({
      query: (params) => ({
        url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/products`,
        method: "POST",
        data: {
          ...params,
        },
      }),
      onQueryStarted: globalQueryErrorHandler("GetProductProfitability"),
    }),
    getChargebackMetadata: build.query<
      GetChargebackMetadataResponse,
      GetChargebackMetadataRequest
    >({
      query: (data) => ({
        url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/chargeback/metadata`,
        method: "POST",
        data,
      }),
      onQueryStarted: globalQueryErrorHandler("GetChargebackMetadata"),
    }),
    monthlyVendorProfitabilityData: build.query<
      VendorProfitabilityMonthlySellOutDataResponse,
      VendorMonthlyProfitAndLossArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendorProfitability/monthly`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler("MonthlyVendorProfitabilityData"),
    }),
  }),
});

export const {
  useVendorChannelProfitAndLossQuery,
  useUploadChargebackFileMutation,
  useUploadDirectCostFileMutation,
  useGetProductProfitabilityQuery,
  useVendorDeductionsQuery,
  useUpdateVendorDeductionsMutation,
  useDeleteVendorDeductionsMutation,
  useGetChargebackMetadataQuery,
  useUpdateProductDirectCostsMutation,
  useMonthlyVendorProfitabilityDataQuery,
} = extendedApiSlice;
